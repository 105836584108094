/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect, useState } from "react";
import objectPath from "object-path";
import { useLocation } from "react-router-dom";
import { getBreadcrumbsAndTitle, useSubheader } from "../../_core/MetronicSubheader";
import { Link } from "react-router-dom";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { QuickUserToggler } from "../extras/QuickUserToggler";
import "./subheader.css";
import house from "../../../../Image/house.png";
import "bootstrap/dist/css/bootstrap.min.css";
import main from "../../../../Image/App_logo1.png";
import { MenuBar } from "./Menubar";
import axios from "axios";
import { HOST } from "../../../../container/dr_examination/Redux/DoctorConstant";
import Cookies from "js-cookie";
import AnchorBtn from "../../../../Components/dr.examine/Qbtn1/qbtnAnchor";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";

export function SubHeader({ isSidebarVisible2, setIsSidebarVisible2 }) {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  const history = useHistory();
  const [claimantnames, setclaimantnames] = useState([]);
  const userRoleFromCookies = Cookies.get("userRole");
  // const [isSidebarVisible1, setIsSidebarVisible1] = useState(false);
  // const toggleSidebar1 = () => {
  //   setIsSidebarVisible1(!isSidebarVisible1);
  // };
  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderFixed: objectPath.get(uiService.config, "subheader.fixed"),
      subheaderMobileToggle: objectPath.get(uiService.config, "subheader.mobile-toggle"),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses("subheader_container", true),
      asideClassesFromConfig: uiService.getClasses("aside", true),
      asideSecondaryDisplay: objectPath.get(uiService.config, "aside.secondary.display"),
      asideSelfMinimizeToggle: objectPath.get(uiService.config, "aside.self.minimize.toggle"),
      extrasSearchDisplay: objectPath.get(uiService.config, "extras.search.display"),
      extrasNotificationsDisplay: objectPath.get(uiService.config, "extras.notifications.display"),
      extrasQuickActionsDisplay: objectPath.get(uiService.config, "extras.quick-actions.display"),
      extrasQuickPanelDisplay: objectPath.get(uiService.config, "extras.quick-panel.display"),
      extrasLanguagesDisplay: objectPath.get(uiService.config, "extras.languages.display"),
      extrasUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const breadcrumbs = aside && aside.breadcrumbs.length > 0 ? aside.breadcrumbs : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(aside && aside.title && aside.title.length > 0 ? aside.title : header.title);
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);
  // List of routes where you want to hide the component
  const routesToHide = ["/doctor-app/patientinfo"];
  const routesToHide1 = [`/doctor-app/claimantcompletedform/`];
  const routesToHide3 = [`/claimant/form/`];
  const id1 = location.state?.id;

  // Check if the current route is in the list of routes to hide
  const shouldHideComponent = routesToHide.includes(location.pathname);
  const shouldHideComponent1 = location.pathname.startsWith(routesToHide3);
  const isMatchingRoute = location.pathname.startsWith(routesToHide1);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState("");

  // useEffect(() => {
  //   getPatientList();
  //   getclaimantList();
  // }, []);
  useEffect(() => {
    setclaimantnames("");
    if (shouldHideComponent1) {
      const pathParts = window.location.pathname.split("/");
      const id32 = pathParts[pathParts.length - 1];
      const getclaimantList = async () => {
        setLoading(true);
        try {
          const response = await axios.get(`${HOST}/api/ClaimantForm/${id32}`, { withCredentials: true });
          setLoading(false);
          setclaimantnames(response.data);
        } catch (error) {
          setLoading(false);
        }
      };
      getclaimantList();
    } else {
      if(id1){
        const getPatientList = async () => {
          setLoading(true);
          try {
            const response = await axios.get(`${HOST}/api/patient/${id1}`, { withCredentials: true });
            setLoading(false);
            setTableData([response.data]);
          } catch (error) {
            setLoading(false);
          }
        };
        getPatientList();
      }
    }
  }, [shouldHideComponent1]);

  // const data2 = claimantdata[0];
  const filteredData = tableData.length > 0 ? tableData.filter((item) => item.id === id1) : "";
  return (
    <div className="your-component">
      {loading && (
        <div className="overlay">
          <Spinner className="loading-spinner" animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
      <div className="" style={{ backgroundColor: "#C6E5EF",width:"100%", // Make header sticky
         // Sticks the header at the top
        zIndex: 1}}>
        <div id="kt_subheader" style={{zIndex:999}} className={`subheader subheader1 py-3 py-lg-8 ${layoutProps.subheaderCssClasses}`}>
          {/* {isTabletView && ( <button
                            onClick={toggleSidebar}
                            className="togglebutton "
                            style={{ border: "none", backgroundColor: "white", borderRadius: "10px",marginTop:"60px",marginLeft:"30px" }}
                          >
                            <i style={{ color: "black", backgroundColor: "white", fontSize: "30px" }}>
                              <HiMiniBars3BottomLeft />
                            
                            </i>
                          </button> )} */}

          {shouldHideComponent || shouldHideComponent1 || isMatchingRoute ? (
            <div style={{ marginRight: "40px" }}></div>
          ) : (
            <MenuBar setIsSidebarVisible={setIsSidebarVisible2} isSidebarVisible={isSidebarVisible2} />
          )}
          <img
            className={isMatchingRoute ? "claimantsidemenu" : "claimantsidemenu1"}
            src={main}
            style={{ width: "180px", height: "50px", marginTop: "60px", }}
          />

          <div
            className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-end flex-wrap mobiledisplaynone`}
            style={{ zIndex: "1002"}}
          >
            {shouldHideComponent || shouldHideComponent1 ? (
              <div className="mobiledisplaynone">
                <div style={{ display: "flex",justifyContent: "center", alignItems: "center", marginRight: shouldHideComponent1 ? "80px" : "" }}>
                  <div style={{ marginTop: "70px", marginRight: "20px" }}>
                    <div style={{ textAlign: "center", fontSize: "13px", color: "#404040", fontWeight: "500", fontFamily: "poppins" }}>
                      <label>CLAIMANT NAME</label>
                    </div>

                    <div style={{ textAlign: "center", color: "#C30000", fontFamily: "poppins", textTransform: "uppercase", fontSize: "17px" }}>
                      {/* {filteredData.length > 0 ? filteredData[0].first_name : ""}&nbsp;{filteredData.length > 0 ? filteredData[0].last_name : ""} */}

                      {shouldHideComponent1 ? (
                        <>
                          <div>{claimantnames?.ocf_firstname ? claimantnames?.ocf_firstname : ""}</div>
                          <div>{claimantnames?.ocf_lastname ? claimantnames?.ocf_lastname : ""}</div>
                        </>
                      ) : (
                        // <span>{claimantnames?.first_name?claimantnames?.first_name:""}</span>
                        <>
                          <div>{filteredData.length > 0 ? filteredData[0].first_name : ""}</div>
                          <div>{filteredData.length > 0 ? filteredData[0].last_name : ""}</div>
                        </>
                      )}
                    </div>
                  </div>
                  <div style={{ width: "3px", height: "80px", backgroundColor: "#C30000", marginTop: "60px" }}></div>
                  <div style={{ marginTop: "20%", marginLeft: "30px" }}>
                    <div style={{ textAlign: "center", fontSize: "13px", color: "#404040", fontWeight: "500", fontFamily: "poppins" }}>
                      <label>DOA:</label>
                    </div>
                    <div style={{ textAlign: "center", color: "#C30000", fontFamily: "poppins", textTransform: "uppercase", fontSize: "17px" }}>
                      {/* {filteredData.length > 0 ? filteredData[0].DOA : ""} */}
                      {shouldHideComponent1 ? (
                        <span>{claimantnames?.ocf_accidentdate_txt ? claimantnames?.ocf_accidentdate_txt : ""}</span>
                      ) : (
                        <span>{filteredData.length > 0 ? filteredData[0].DOA : ""}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              " "
            )}
            {(userRoleFromCookies === "AccountManager" || userRoleFromCookies === "DoctorAssistant") && (
              <>
                {userRoleFromCookies === "AccountManager" && !isMatchingRoute && (
                  // <Link to="/doctor-app/assigncard">
                  <AnchorBtn
                    className="btn mt-18 mr-4"
                    style={{ background: "white", padding: "9px", color: "black", border: "1px solid black" }}
                    onClick={() => history.push("/doctor-app/assigncard")}
                  >
                    Assign
                  </AnchorBtn>
                )}
                {isMatchingRoute ? (
                  <div></div>
                ) : (
                  <AnchorBtn
                    className="btn mt-18 mr-4"
                    style={{ background: "white", padding: "9px", color: "black", border: "1px solid black" }}
                    onClick={() => history.push("/doctor-app/frontdesk_session")}
                  >
                    Session
                  </AnchorBtn>
                )}
              </>
            )}
            <div style={{ display: "flex" }}>
              {userRoleFromCookies !== "claimant" && (
                <Link to="/dashboard">
                  <img src={house} width={"60px"} style={{}} className="mobiles" />
                </Link>
              )}
            </div>

            <div className="d-flex align-items-center flex-wrap mr-1"></div>
            {layoutProps.extrasUserDisplay && <QuickUserToggler />}
          </div>
          {/* {layoutProps.extrasUserDisplay && <QuickUserToggler />} */}
        </div>
      </div>
    </div>
  );
}
